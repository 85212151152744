import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { isTest } from '../environment';

import { Language } from './types';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.EN,
    supportedLngs: [Language.ES, Language.EN],
    debug: isTest(),
    saveMissing: isTest(),
    // eslint-disable-next-line no-console
    missingKeyHandler: console.debug,

    interpolation: {
      escapeValue: false,
    },
  });

// eslint-disable-next-line import/no-default-export
export default i18n.t;
