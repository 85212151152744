/**
 * Resize Observer
 */
(async () => {
  if (!('ResizeObserver' in window)) {
    // Loads polyfill asynchronously, only if required.
    const module = await import('@juggle/resize-observer');
    // @ts-ignore
    window.ResizeObserver = module.ResizeObserver;
  }
})();
