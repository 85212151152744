export const breakpoints = {
  xs: 360,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1540,

  mobile: 1280,
  desktop: 1540,
  wide: 2560,
};

export const breakpointsKeys = Object.keys(breakpoints);

export type Breakpoint = keyof typeof breakpoints;
